window.addEventListener('load', () => {
    const items = document.querySelectorAll('[password-toggle]');

    items.forEach(item => {
        item.addEventListener('click', () => toggle(item));
    });

    function toggle(item) {
        const input = item.parentNode.querySelector('input');
        if (input.type === 'password') {
           input.type = 'text';
           item.classList.add('password-toggle__icon--show');
        } else {
           input.type = 'password';
           item.classList.remove('password-toggle__icon--show');
        }
    }
});
